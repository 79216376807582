import React, { useState, useEffect } from 'react';
import './Style.css';

const Alphabot: React.FC<{ user: any }> = ({ user }) => {
  const [active, setActive] = useState(false);
  const [totalRaffles, setTotalRaffles] = useState(0);
  const [apiKey, setApiKey] = useState('');
  
  useEffect(() => {
    const fetchAlphabotSettings = async () => {
      try {
        const response = await fetch(`/api/alphabot/${user.id}`);
        if (!response.ok) {
          throw new Error('Error loading settings');
        }
        const data = await response.json();
        setActive(data.active);
        setTotalRaffles(data.totalRaffles);
        setApiKey(data.apiKey);
      } catch (error) {
        console.error('Error fetching alphabot settings:', error);
      }
    };

    fetchAlphabotSettings();
  }, [user]);

  const handleSave = async () => {
    try {
      const response = await fetch('/api/alphabot', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          active,
          apiKey,
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving data');
      }

      alert('Data saved successfully!');
    } catch (error) {
      console.error('Error saving alphabot data:', error);
      alert('You do not have permission for that. Please contact us on Discord.');
    }
  };

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>Alphabot</h2>
          <p>The bot automatically enters Alphabot Raffles that <u>only require Twitter</u>. Raffles that require Discord, NFT, and others the bot does not attempt to enter. The bot runs every 30 minutes.</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <h3>Alphabot Settings</h3>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            Active
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Total Raffles entred:
            <span className="total-raffles-label">{totalRaffles}</span>
          </label>
        </div>
        <div className="webhook-group">
          <label>
            ApiKey:
            <input
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
            />
          </label>
        </div>
        <button onClick={handleSave}>Save Settings</button>
      </div>
    </div>
  );
};

export default Alphabot;
