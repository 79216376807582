import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import './Style.css';

const TokenSales: React.FC<{ user: any }> = ({ user }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [active, setActive] = useState(false);
  const [startDate, setStartDate] = useState(''); 
  const [webhook, setWebhook] = useState('');
  const [wallets, setWallets] = useState('');
  const [discordServerId, setDiscordServerId] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_ENV === 'test'
    ? process.env.REACT_APP_TEST_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchTokenSalesSettings = async () => {
      try {
        const response = await fetch(`/api/tokensales/${user.id}`);
        if (!response.ok) {
          throw new Error('Error loading settings');
        }
        const data = await response.json();
        setActive(data.active);
        setStartDate(data.startDate ? new Date(data.startDate).toISOString().split('T')[0] : ''); // Setar startDate
        setWebhook(data.webhook);
        setWallets(data.wallets);
        setDiscordServerId(data.discordServerId || '');
      } catch (error) {
        console.error('Error fetching token sales settings:', error);
      }
    };

    fetchTokenSalesSettings();

    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.guild_id) {
      setDiscordServerId(parsedQuery.guild_id as string);
      handleSave(parsedQuery.guild_id as string);
      // Remove guild_id from the URL
      navigate(location.pathname, { replace: true });
    }
  }, [user, location, navigate]);

  const handleSave = async (serverId: string = discordServerId) => {
    try {
      const response = await fetch(`/api/tokensales`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          active,
          startDate,
          webhook,
          wallets,
          discordServerId: serverId,
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving data');
      }

      alert('Data saved successfully!');
    } catch (error) {
      console.error('Error saving token sales data:', error);
      alert('You do not have permission for that. Please contact us on Discord.');
    }
  };

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>Token Sales Bot</h2>
          <p>The Token Sales Bot allows you to track and participate in token sales automatically, maximizing your investment opportunities in the cryptocurrency market.</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <h3>Token Sales Settings</h3>
        <div className="settings-header">
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
              Active
            </label>
          </div>
          <div className="button-group spaced">
            <button
              onClick={() => {
                const redirectUri = `${backendUrl}/dashboard?section=TokenSales`; // Certifique-se de que este URI esteja registrado no Discord Developer Portal
                window.location.href = `https://discord.com/oauth2/authorize?client_id=1246962126900428961&permissions=268453888&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&integration_type=0&scope=bot+guilds`;
              }}
              disabled={!!discordServerId}
              className="discord-button"
            >
              Install Discord Bot
            </button>
          </div>
        </div>
        <div className="webhook-group">
          <label>
            Start Date:
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Webhook:
            <input
              type="text"
              value={webhook}
              onChange={(e) => setWebhook(e.target.value)}
            />
          </label>
        </div>
        <div className="wallets-group">
          <label>
            Wallets:
            <textarea
              value={wallets}
              onChange={(e) => setWallets(e.target.value)}
              rows={10}
            />
          </label>
        </div>
        <button onClick={() => handleSave()}>Save Settings</button>
      </div>
    </div>
  );
};

export default TokenSales;
