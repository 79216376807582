import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Raises from './Raises';
import TokenSales from './TokenSales';
import HomePage from './HomePage';
import Alphabot from './Alphabot';
import Rumble from './Rumble';
import DiscordRoles from './DiscordRoles';
import checkBackendConnection from '../utils/checkBackendConnection';
import useInactivityTimeout from '../hooks/useInactivityTimeout';

const Dashboard: React.FC = () => {
  const [user, setUser] = useState<any>(null);
  const [activeMenu, setActiveMenu] = useState<string>('Home');
  const [menuSettings, setMenuSettings] = useState<{ raisesEnabled: boolean; tokenSalesEnabled: boolean; alphabotEnabled: boolean; rumbleEnabled: boolean; rolesEnabled: boolean } | null>(null); // Inclua discordRolesEnabled aqui
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useInactivityTimeout(30 * 60 * 1000); // 30 minutes

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetch('/auth/user', {
        credentials: 'include'
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
      } else {
        console.error('Error fetching user data:', response.statusText);
      }
    };

    const fetchMenuSettings = async () => {
      try {
        const response = await fetch('/api/menu-settings');
        if (response.ok) {
          const data = await response.json();
          setMenuSettings({
            raisesEnabled: data.raisesEnabled,
            tokenSalesEnabled: data.tokenSalesEnabled,
            alphabotEnabled: data.alphabotEnabled,
            rumbleEnabled: data.rumbleEnabled,
            rolesEnabled: data.rolesEnabled
          });
        } else {
          console.error('Error fetching menu settings:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching menu settings:', error);
      } finally {
        setIsLoading(false);
      }
    };

    const interval = setInterval(async () => {
      const isConnected = await checkBackendConnection();
      if (!isConnected) {
        console.error('Redirecting to login due to backend connection loss');
        navigate('/');
      }
    }, 10000);

    fetchUserData();
    fetchMenuSettings();

    return () => clearInterval(interval);
  }, [navigate]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const section = queryParams.get('section');
    if (section) {
      setActiveMenu(section);
    }
  }, [location]);

  const handleLogout = () => {
    fetch('/auth/logout', {
      method: 'POST',
      credentials: 'include'
    }).then(() => {
      window.location.href = '/';
    });
  };

  if (!user || isLoading) {
    return <div>Loading...</div>;
  }

  const renderContent = () => {
    switch (activeMenu) {
      case 'Raises':
        return <Raises user={user} />;
      case 'TokenSales':
        return <TokenSales user={user} />;
      case 'Alphabot':
        return <Alphabot user={user} />;
      case 'Rumble': 
        return <Rumble user={user} />;
      case 'DiscordRoles': 
        return <DiscordRoles user={user} />;
      case 'Home':
      default:
        return <HomePage user={user}/>;
    }
  };

  return (
    <div style={{ display: 'flex', height: '100vh', background: '#1e1e1e', color: '#ffffff' }}>
      <Sidebar
        setActiveMenu={setActiveMenu}
        activeMenu={activeMenu}
        user={user}
        handleLogout={handleLogout}
        menuSettings={menuSettings}
      />
      <main style={{ flex: 1, padding: '1rem' }}>
        <section>
          {renderContent()}
        </section>
      </main>
    </div>
  );
};

export default Dashboard;
