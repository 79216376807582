import React, { useState, useEffect, useCallback } from 'react';
import './Style.css';
import bs58 from 'bs58';
import {
  WalletProvider,
  ConnectionProvider,
  useWallet,
} from '@solana/wallet-adapter-react';
import { WalletModalButton, WalletModalProvider, WalletMultiButton } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl, Transaction, PublicKey, Connection, TransactionInstruction } from '@solana/web3.js';

require('@solana/wallet-adapter-react-ui/styles.css');

interface UserWallet {
  id: number;
  userId: number;
  network: string;
  wallet: string;
}

const UserWalletsPage: React.FC<{ user: any }> = ({ user }) => {
  const [userWallets, setUserWallets] = useState<UserWallet[]>([]);
  const [isProcessingPolygon, setIsProcessingPolygon] = useState(false);
  const [isProcessingBase, setIsProcessingBase] = useState(false);
  const [usingLedger, setUsingLedger] = useState(false);
  const { publicKey, signMessage, connected, disconnect, signTransaction } = useWallet();

  const connection = new Connection(
    'https://solana-mainnet.g.alchemy.com/v2/gziv3EVugY8gyBB0wuSQKMSDSKq61qjU', 
    "confirmed"
  );

  useEffect(() => {
    fetchUserWallets();
  }, []);

  const fetchUserWallets = async () => {
    try {
      const response = await fetch(`/api/user-wallets/${user.id}`);
      if (!response.ok) {
        throw new Error('Error loading wallets');
      }
      const data: UserWallet[] = await response.json();
      setUserWallets(data);
    } catch (error) {
      console.error('Error fetching user wallets:', error);
    }
  };

  const connectMetaMaskWallet = async (network: string) => {
    try {
      const ethereum = (window as any).ethereum;
      if (ethereum) {
        if (network === 'Polygon') {
          setIsProcessingPolygon(true);
        } else if (network === 'Base') {
          setIsProcessingBase(true);
        }
        const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
        const walletAddress = accounts[0];
        const message = `Please sign this message to confirm you own the wallet address ${walletAddress}`;
        const signature = await ethereum.request({
          method: 'personal_sign',
          params: [message, walletAddress],
        });
        await addWallet(walletAddress, signature, message, network, '', usingLedger);
      } else {
        alert('MetaMask not found. Please install it.');
      }
    } catch (error) {
      console.error(`Error connecting to MetaMask for ${network}:`, error);
      alert(`Error connecting to MetaMask for ${network}`);
    } finally {
      if (network === 'Polygon') {
        setIsProcessingPolygon(false);
      } else if (network === 'Base') {
        setIsProcessingBase(false);
      }
    }
  };

  const addWallet = async (walletAddress: string, signature: string, message: string, network: string, serializedTx: any, ledger: boolean) => {
    try {
      const response = await fetch('/api/user-wallets', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          network,
          wallet: walletAddress,
          signature,
          message,
          serializedTx,
          ledger
        }),
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error adding wallet');
      }
      const data: UserWallet = await response.json();
      setUserWallets([...userWallets, data]);
    } catch (error) {
      console.error('Error adding wallet:', error);
      alert(error || 'Error adding wallet');
    }
  };

  const handleDeleteWallet = async (id: number) => {
    try {
      const response = await fetch(`/api/user-wallets/${id}`, {
        method: 'DELETE',
      });
      if (!response.ok) {
        throw new Error('Error deleting wallet');
      }
      setUserWallets(userWallets.filter(wallet => wallet.id !== id));
    } catch (error) {
      console.error('Error deleting wallet:', error);
      alert('Error deleting wallet');
    }
  };

  const handleWalletSignMessage = useCallback(async () => {
    if (publicKey && connected) {
      try {
        const walletAddress = publicKey.toString();
        const message = `Please sign this message to confirm you own the wallet address ${walletAddress}`;
        const encodedMessage = new TextEncoder().encode(message);
        let signature = '';

        if (!usingLedger && signMessage) {
          const signedMessage = await signMessage(encodedMessage);
          signature = bs58.encode(signedMessage);
          await addWallet(walletAddress, signature, message, 'Solana', '', usingLedger);
        } else if (usingLedger && signTransaction) {
          const MEMO_PROGRAM_ID = new PublicKey(
            "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
          );

          const buildAuthTx = async (nonce: string): Promise<Transaction> => {
            const tx = new Transaction();
            tx.add(
              new TransactionInstruction({
                programId: MEMO_PROGRAM_ID,
                keys: [],
                data: Buffer.from(nonce, "utf8"),
              })
            );
            return tx;
          };

          const tx = await buildAuthTx(message);
          tx.feePayer = publicKey;
          tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

          if (signTransaction) {
            const signedTx = await signTransaction(tx);
            const serializedTx = signedTx.serialize();
            const base58SerializedTx = bs58.encode(serializedTx);
            await addWallet(walletAddress, signature, message, 'Solana', base58SerializedTx, usingLedger);
          } else {
            console.error('signTransaction method is not available');
            return;
          }
        } else {
          console.error('Wallet does not support message or transaction signing!');
          return;
        }
        await disconnect();
      } catch (error) {
        console.error('Error signing message:', error);
        alert('Error signing message');
      }
    }
  }, [publicKey, signMessage, connected, disconnect, usingLedger, signTransaction]);

  useEffect(() => {
    console.log(`useEffect for connected: ${connected}`);
    if (connected) {
      handleWalletSignMessage();
    }
  }, [connected, handleWalletSignMessage]);

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>User Wallets</h2>
          <p>Link your wallets here to verify on Discord.</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <div className="ledger-switch">
          <label className="switch">
            <input
              type="checkbox"
              checked={usingLedger}
              onChange={(e) => setUsingLedger(e.target.checked)}
            />
            <span className="slider"></span>
          </label>
          <span>Using Ledger?</span>
        </div>
        <div className="wallets-group">
          <WalletModalButton className="wallet-adapter-button">Solana</WalletModalButton>
          <button onClick={() => connectMetaMaskWallet('Polygon')} disabled={isProcessingPolygon} className="connect-wallet-button">
            {isProcessingPolygon ? 'Connecting...' : 'Polygon'}
          </button>
          <button onClick={() => connectMetaMaskWallet('Base')} disabled={isProcessingBase} className="connect-wallet-button">
            {isProcessingBase ? 'Connecting...' : 'Base'}
          </button>
        </div>
        <hr className="separator" />
        <div className="wallets-table">
          <table className="custom-table">
            <thead>
              <tr>
                <th>Network</th>
                <th>Wallet Address</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {userWallets.map(wallet => (
                <tr key={wallet.id}>
                  <td>{wallet.network}</td>
                  <td>{wallet.wallet}</td>
                  <td>
                    <button
                      className="remove-role-button"
                      onClick={() => handleDeleteWallet(wallet.id)}
                      disabled={isProcessingPolygon || isProcessingBase}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const App: React.FC<{ user: any }> = ({ user }) => {
  const endpoint = clusterApiUrl('mainnet-beta');
  const wallets = [new PhantomWalletAdapter()];

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        <WalletModalProvider>
          <UserWalletsPage user={user} />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
};

export default App;
