import React, { useState, useEffect } from 'react';
import './Style.css';

const Raises: React.FC<{ user: any }> = ({ user }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [cryptoFundraising, setCryptoFundraising] = useState(false);
  const [defillama, setDefillama] = useState(false);
  const [webhook, setWebhook] = useState('');
  const [active, setActive] = useState(false);
  
  useEffect(() => {
    const fetchRaiseSettings = async () => {
      try {
        const response = await fetch(`/api/raises/${user.id}`);
        if (!response.ok) {
          throw new Error('Error loading settings');
        }
        const data = await response.json();
        setCryptoFundraising(data.cryptoFundraising);
        setDefillama(data.defillama);
        setWebhook(data.webhook);
        setActive(data.active);
      } catch (error) {
        console.error('Error fetching raise settings:', error);
      }
    };

    fetchRaiseSettings();
  }, [user]);

  const handleSave = async () => {
    try {
      const response = await fetch('/api/raises', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          cryptoFundraising,
          defillama,
          webhook,
          active,
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving data');
      }

      alert('Data saved successfully!');
    } catch (error) {
      console.error('Error saving raise data:', error);
      alert('You do not have permission for that. Please contact us on Discord.');
    }
  };

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>Raises Bot</h2>
          <p>The Raises Bot monitors new crypto project raises in real-time and automatically sends updates to Discord. It makes it easy to view and track new investment opportunities, ensuring you are always informed about the latest developments in the crypto world.</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <h3>Raises Settings</h3>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            Active
          </label>
          <label>
            <input
              type="checkbox"
              checked={cryptoFundraising}
              onChange={(e) => setCryptoFundraising(e.target.checked)}
            />
            Crypto Fundraising
          </label>
          <label>
            <input
              type="checkbox"
              checked={defillama}
              onChange={(e) => setDefillama(e.target.checked)}
            />
            Defillama
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Webhook:
            <input
              type="text"
              value={webhook}
              onChange={(e) => setWebhook(e.target.value)}
            />
          </label>
        </div>
        <button onClick={handleSave}>Save Settings</button>
      </div>
    </div>
  );
};

export default Raises;
