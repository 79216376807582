import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useInactivityTimeout = (timeout: number) => {
  const navigate = useNavigate();

  useEffect(() => {
    let logoutTimer: NodeJS.Timeout;
    const events = ['mousemove', 'mousedown', 'keypress', 'scroll', 'touchstart'];

    const resetTimeout = () => {
      if (logoutTimer) clearTimeout(logoutTimer);
      logoutTimer = setTimeout(() => {
        navigate('/');
      }, timeout);
    };

    events.forEach(event => window.addEventListener(event, resetTimeout));

    resetTimeout();

    return () => {
      clearTimeout(logoutTimer);
      events.forEach(event => window.removeEventListener(event, resetTimeout));
    };
  }, [navigate, timeout]);
};

export default useInactivityTimeout;
