import React, { useEffect, useState } from 'react';
import axios from 'axios';
import logo from '../images/logo_kodweb3.png';

interface SidebarProps {
  setActiveMenu: (menu: string) => void;
  activeMenu: string;
  user: any;
  handleLogout: () => void;
  menuSettings: { raisesEnabled: boolean; tokenSalesEnabled: boolean; alphabotEnabled: boolean; rumbleEnabled: boolean; rolesEnabled: boolean } | null;
}

const Sidebar: React.FC<SidebarProps> = ({ setActiveMenu, activeMenu, user, handleLogout, menuSettings }) => {
  const [menuAccess, setMenuAccess] = useState({
    Raises: false,
    TokenSales: false,
    Alphabot: false,
    Rumble: false,
    DiscordRoles: false
  });

  // Função para verificar o acesso a um menu específico
  const checkMenuAccess = async (roleId: string) => {
    try {
      const response = await axios.post('/api/sidebar-enable-disable', { userId: user.id, roleId });
      return response.data.access;
    } catch (error) {
      console.error(`Erro ao verificar acesso para ${roleId}:`, error);
      return false; // Em caso de erro, retorna false para ocultar o menu
    }
  };

  // Função para buscar acessos de todos os menus
  const fetchMenuAccess = async () => {
    const updatedMenuAccess = { ...menuAccess };

    // Verifica cada menu separadamente e atualiza o estado
    updatedMenuAccess.Raises = await checkMenuAccess('Raises');
    updatedMenuAccess.TokenSales = await checkMenuAccess('TokenSales');
    updatedMenuAccess.Alphabot = await checkMenuAccess('Alphabot');
    updatedMenuAccess.Rumble = await checkMenuAccess('Rumble');
    updatedMenuAccess.DiscordRoles = await checkMenuAccess('DiscordRoles');

    setMenuAccess(updatedMenuAccess);
  };

  // Buscar permissões ao carregar o componente ou quando o usuário mudar
  useEffect(() => {
    if (user) {
      fetchMenuAccess();
    }
  }, [user]);

  return (
    <div style={{ width: '200px', background: '#2c2c2c', color: '#ffffff', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh' }}>
      <div>
        <div className="logo-container" style={{ textAlign: 'center', padding: '1rem' }}>
          <img src={logo} alt="Logo" style={{ width: '100px', height: '100px', borderRadius: '50%' }} />
        </div>
        <div
          onClick={() => setActiveMenu('Home')}
          style={{ padding: '1rem', cursor: 'pointer', background: activeMenu === 'Home' ? '#3a3a3a' : 'inherit' }}
        >
          Home
        </div>
        {user.admin && (
          <div
            onClick={() => setActiveMenu('Admin')}
            style={{ padding: '1rem', cursor: 'pointer', background: activeMenu === 'Admin' ? '#3a3a3a' : 'inherit' }}
          >
            Admin
          </div>
        )}
        <hr style={{ borderColor: '#444' }} />
        <div style={{ padding: '1rem', fontWeight: 'bold', textAlign: 'center' }}>Bots</div>
        {(menuSettings?.raisesEnabled || user.admin) && menuAccess.Raises && (
          <div
            onClick={() => setActiveMenu('Raises')}
            style={{
              padding: '1rem',
              cursor: 'pointer',
              background: activeMenu === 'Raises' ? '#3a3a3a' : 'inherit',
            }}
          >
            Raises
          </div>
        )}
        {(menuSettings?.tokenSalesEnabled || user.admin) && menuAccess.TokenSales && (
          <div
            onClick={() => setActiveMenu('TokenSales')}
            style={{
              padding: '1rem',
              cursor: 'pointer',
              background: activeMenu === 'TokenSales' ? '#3a3a3a' : 'inherit',
            }}
          >
            Token Sales
          </div>
        )}
        {(menuSettings?.alphabotEnabled || user.admin) && menuAccess.Alphabot && (
          <div
            onClick={() => setActiveMenu('Alphabot')}
            style={{
              padding: '1rem',
              cursor: 'pointer',
              background: activeMenu === 'Alphabot' ? '#3a3a3a' : 'inherit',
            }}
          >
            Alphabot
          </div>
        )}
        {(menuSettings?.rumbleEnabled || user.admin) && menuAccess.Rumble && (
          <div
            onClick={() => setActiveMenu('Rumble')}
            style={{
              padding: '1rem',
              cursor: 'pointer',
              background: activeMenu === 'Rumble' ? '#3a3a3a' : 'inherit',
            }}
          >
            Rumble
          </div>
        )}
        {(menuSettings?.rolesEnabled || user.admin) && menuAccess.DiscordRoles && (
          <div
            onClick={() => setActiveMenu('DiscordRoles')}
            style={{
              padding: '1rem',
              cursor: 'pointer',
              background: activeMenu === 'DiscordRoles' ? '#3a3a3a' : 'inherit',
            }}
          >
            Discord Roles
          </div>
        )}
      </div>
      <div style={{ padding: '1rem', borderTop: '1px solid #444', display: 'flex', alignItems: 'center' }}>
        {user.avatar && (
          <img src={user.avatar} alt="Avatar" style={{ borderRadius: '50%', width: '40px', height: '40px', marginRight: '10px' }} />
        )}
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <button onClick={handleLogout} style={{ marginTop: '10px', background: 'none', border: 'none', color: '#ffffff', cursor: 'pointer' }}>Logout</button>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
