import React from 'react';

const Login: React.FC = () => {
  // Log para verificar o valor de REACT_APP_ENV
  console.error('REACT_APP_ENV:', process.env.REACT_APP_ENV);
  
  // Determine the correct backend URL based on the custom environment variable
  const backendUrl = process.env.REACT_APP_ENV === 'test'
    ? process.env.REACT_APP_TEST_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL;

  // Log para verificar as URLs de backend
  console.error('Backend URL:', backendUrl);

  const handleLogin = () => {
    window.location.href = `${backendUrl}/auth/discord`;
  };

  return (
    <div style={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center', backgroundColor: '#1e1e1e' }}>
      <div style={{ textAlign: 'center', color: '#ffffff' }}>
        <img src="/images/logo_kodweb3.png" alt="" style={{ marginBottom: '10px', width: '200px', height: 'auto', borderRadius: '180px' }} />
        <button onClick={handleLogin} style={{
          padding: '10px 20px',
          fontSize: '16px',
          cursor: 'pointer',
          backgroundColor: '#3ba55d', 
          color: '#ffffff',
          border: 'none',
          borderRadius: '5px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
          transition: 'background-color 0.3s ease',
          display: 'block',
          margin: '0 auto',
        }}
        onMouseEnter={(e) => {
          (e.target as HTMLButtonElement).style.backgroundColor = '#2e8c4b'; 
        }}
        onMouseLeave={(e) => {
          (e.target as HTMLButtonElement).style.backgroundColor = '#3ba55d'; 
        }}
        >
          Login with Discord
        </button>
      </div>
    </div>
  );
};

export default Login;
