import React, { useState, useEffect } from 'react';
import './Style.css';

const Rumble: React.FC<{ user: any }> = ({ user }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [active, setActive] = useState(false);
  const [discordId, setDiscordId] = useState('');
  const [initialMessage, setInitialMessage] = useState('');
  const [winMessage, setWinMessage] = useState('');
  const [excludeServer, setExcludeServer] = useState(''); // Novo estado para excludeServer
  const [privateMessage, setPrivateMessage] = useState(false);
  const [totalRumbleEntered, setTotalRumbleEntered] = useState(0);
  
  useEffect(() => {
    const fetchRumbleSettings = async () => {
      try {
        const response = await fetch(`/api/rumble/${user.id}`);
        if (!response.ok) {
          throw new Error('Error loading settings');
        }
        const data = await response.json();
        setActive(data.active);
        setDiscordId(data.discordId);
        setInitialMessage(data.initialMessage);
        setWinMessage(data.winMessage);
        setExcludeServer(data.excludeServer); // Atualizar o estado de excludeServer
        setPrivateMessage(data.privateMessage);
        setTotalRumbleEntered(data.totalRumbleEntered);
      } catch (error) {
        console.error('Error fetching rumble settings:', error);
      }
    };

    fetchRumbleSettings();
  }, [user]);

  const handleSave = async () => {
    try {
      const response = await fetch('/api/rumble', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          active,
          discordId,
          initialMessage,
          winMessage,
          excludeServer, // Incluir excludeServer no corpo da requisição
          privateMessage,
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving data');
      }

      alert('Data saved successfully!');
    } catch (error) {
      console.error('Error saving rumble data:', error);
      alert('You do not have permission for that. Please contact us on Discord.');
    }
  };

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>Rumble Bot</h2>
          <p>The bot will automatically enter all Rumbles on all servers it is in. <u>This is not authorized by Discord and puts your account at risk.</u></p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <h3>Rumble Settings</h3>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            Active
          </label>
          <label>
            <input
              type="checkbox"
              checked={privateMessage}
              onChange={(e) => setPrivateMessage(e.target.checked)}
            />
            Win Private Message
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Discord ID:
            <input
              type="text"
              value={discordId}
              onChange={(e) => setDiscordId(e.target.value)}
            />
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Initial Message: - Separate the Messages by ;
            <input
              type="text"
              value={initialMessage}
              onChange={(e) => setInitialMessage(e.target.value)}
            />
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Win Message: - Separate the Messages by ;
            <input
              type="text"
              value={winMessage}
              onChange={(e) => setWinMessage(e.target.value)}
            />
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Exclude Server IDs: - Separate the IDs by ;
            <input
              type="text"
              value={excludeServer}
              onChange={(e) => setExcludeServer(e.target.value)}
            />
          </label>
        </div>
        <div className="webhook-group">
          <label>
            Total Rumbles Entered:
            <span className="total-raffles-label">{totalRumbleEntered}</span>
          </label>
        </div>
        <button onClick={handleSave}>Save Settings</button>
      </div>
    </div>
  );
};

export default Rumble;
