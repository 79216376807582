const checkBackendConnection = async () => {
  try {
    const response = await fetch('/api/health-check', {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (response.headers.get('content-type')?.includes('application/json')) {
      const data = await response.json();
      return data.status === 'ok';
    } else {
      console.error('Unexpected content type:', response.headers.get('content-type'));
      return false;
    }
  } catch (error) {
    console.error('Error checking backend connection:', error);
    return false;
  }
};

export default checkBackendConnection;
