import React, { useState, useEffect } from 'react';
import './Style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import logo from '../images/logo_kodweb3.png';

const DiscordRoles: React.FC<{ user: any }> = ({ user }) => {
  const [active, setActive] = useState(false);
  const [servers, setServers] = useState<any[]>([]);
  const [roles, setRoles] = useState<any[]>([]);
  const [selectedServer, setSelectedServer] = useState<string | null>(null);
  const [rolesServer, setRolesServer] = useState<any[]>([]);
  const location = useLocation();
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_ENV === 'test'
    ? process.env.REACT_APP_TEST_BACKEND_URL
    : process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetch(`/api/discord-roles/${user.id}`);
        if (!response.ok) {
          throw new Error('Error loading settings');
        }
        const data = await response.json();
        setActive(data.active);
      } catch (error) {
        console.error('Error fetching Discord roles settings:', error);
      }
    };

    const fetchServers = async () => {
      try {
        const response = await fetch(`/api/servers/${user.id}`);
        if (!response.ok) {
          throw new Error('Error fetching servers');
        }
        const data = await response.json();
        setServers(data);
      } catch (error) {
        console.error('Error fetching servers:', error);
      }
    };

    fetchSettings();
    fetchServers();

    const parsedQuery = queryString.parse(location.search);
    if (parsedQuery.guild_id) {
      handleAddServer(parsedQuery.guild_id as string);
      // Remove guild_id from the URL
      navigate(location.pathname, { replace: true });
    }
  }, [user, location, navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      if (selectedServer) {
        try {
          const response = await fetch(`/api/servers/roles/${selectedServer}`);
          if (!response.ok) {
            throw new Error('Error fetching roles');
          }
          const data = await response.json();
          setRoles(data);
        } catch (error) {
          console.error('Error fetching roles:', error);
        }
      }
    };

    const fetchRolesServer = async () => {
      if (selectedServer) {
        try {
          const response = await fetch(`/api/role-servers/${selectedServer}`);
          if (!response.ok) {
            throw new Error('Error fetching roles server');
          }
          const data = await response.json();
          setRolesServer(data);
        } catch (error) {
          console.error('Error fetching roles server:', error);
        }
      }
    };

    fetchRoles();
    fetchRolesServer();
  }, [selectedServer]);

  const handleSaveSettings = async () => {
    try {
      const response = await fetch('/api/discord-roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          active,
        }),
      });

      if (!response.ok) {
        throw new Error('Error saving settings');
      }

      alert('Settings saved successfully!');
    } catch (error) {
      console.error('Error saving settings:', error);
      alert('You do not have permission for that. Please contact us on Discord.');
    }
  };

  const handleAddServer = async (serverId: string = '') => {
    if (!serverId) {
      const redirectUri = `${backendUrl}/dashboard?section=DiscordRoles`;
      window.location.href = `https://discord.com/oauth2/authorize?client_id=1200890890089148557&permissions=268454932&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}&scope=bot+messages.read+applications.commands+identify+guilds`;
      return;
    }

    try {
      const response = await fetch('/api/servers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: user.id,
          serverId,
        }),
      });

      if (response.status === 400) {
        alert('Server already exists');
        return;
      }

      if (!response.ok) {
        throw new Error('Error adding server');
      }

      const data = await response.json();
      setServers((prevServers) => [...prevServers, data]);
    } catch (error) {
      console.error('Error adding server:', error);
      alert('Error adding server');
    }
  };

  const handleAddRole = () => {
    setRolesServer([...rolesServer, { id: null, roleId: '', nftCollection: '', network: 'Polygon', quantity: 1 }]);
  };

  const handleRoleChange = (index: number, roleId: string) => {
    const updatedRolesServer = [...rolesServer];
    updatedRolesServer[index].roleId = roleId;
    setRolesServer(updatedRolesServer);
  };

  const handleNftCollectionChange = (index: number, nftCollection: string) => {
    const updatedRolesServer = [...rolesServer];
    updatedRolesServer[index].nftCollection = nftCollection;
    setRolesServer(updatedRolesServer);
  };

  const handleNetworkChange = (index: number, network: string) => {
    const updatedRolesServer = [...rolesServer];
    updatedRolesServer[index].network = network;
    setRolesServer(updatedRolesServer);
  };

  const handleQuantityChange = (index: number, quantity: string) => {
    const updatedRolesServer = [...rolesServer];
    const parsedQuantity = parseInt(quantity, 10);

    // Garante que a quantidade seja no mínimo 1
    updatedRolesServer[index].quantity = parsedQuantity > 0 ? parsedQuantity : 1;

    setRolesServer(updatedRolesServer);
  };

  const handleSaveRole = async (index: number) => {
    const role = rolesServer[index];
    try {
      const response = await fetch('/api/role-servers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id: role.id,
          serverId: selectedServer,
          roleId: role.roleId,
          nftCollection: role.nftCollection,
          quantity: role.quantity,
          network: role.network,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Error saving role');
      }

      const data = await response.json();
      const updatedRolesServer = [...rolesServer];
      updatedRolesServer[index] = data;
      setRolesServer(updatedRolesServer);
      alert('Role saved successfully!');
    } catch (error: any) {
      console.error('Error saving role:', error);
      alert(error.message || 'Error saving role');
    }
  };

  const handleDeleteRole = async (index: number) => {
    const role = rolesServer[index];
    if (!role.id) {
      // If role doesn't have an ID, it means it's a new role that hasn't been saved yet.
      const updatedRolesServer = [...rolesServer];
      updatedRolesServer.splice(index, 1);
      setRolesServer(updatedRolesServer);
      return;
    }

    try {
      const response = await fetch(`/api/role-servers/${role.id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error deleting role');
      }

      const updatedRolesServer = [...rolesServer];
      updatedRolesServer.splice(index, 1);
      setRolesServer(updatedRolesServer);
      alert('Role deleted successfully!');
    } catch (error: any) {
      console.error('Error deleting role:', error);
      alert(error.message || 'Error deleting role');
    }
  };

  const handleDeleteServer = async (serverId: string) => {
    try {
      const response = await fetch(`/api/servers/${serverId}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Error deleting server');
      }

      const updatedServers = servers.filter(server => server.serverId !== serverId);
      setServers(updatedServers);

      if (selectedServer === serverId) {
        setSelectedServer(null);
        setRolesServer([]);
      }

      alert('Server deleted successfully!');
    } catch (error: any) {
      console.error('Error deleting server:', error);
      alert('Error deleting server');
    }
  };

  const getAvatarUrl = (avatarUrl: string) => {
    return avatarUrl.includes('null.png') ? logo : avatarUrl;
  };

  return (
    <div className="raises-container">
      <div className="raises-header">
        <div className="header-left">
          <h2>Discord Roles Bot</h2>
          <p>The Discord Roles Bot allows you to manage Discord roles efficiently, streamlining user permissions and role assignments within your server.</p>
        </div>
      </div>
      <hr className="separator" />
      <div className="raises-config">
        <h3>Discord Roles Settings</h3>
        <div className="checkbox-group">
          <label>
            <input
              type="checkbox"
              checked={active}
              onChange={(e) => setActive(e.target.checked)}
            />
            Active
          </label>
          <button onClick={handleSaveSettings}>Save Settings</button>
        </div>
      </div>
      <hr className="separator" />
      <div className="servers-list-container">
        <button className="add-server-button" onClick={() => handleAddServer()}>+</button>
        <ul className="servers-list">
          {servers.map((server) => (
            <li
              key={server.id}
              onClick={() => setSelectedServer(server.serverId)}
              className={selectedServer === server.serverId ? 'selected' : ''}
            >
              <img
                src={getAvatarUrl(server.avatarUrl)}
                alt={`Server ${server.serverId}`}
                className="server-avatar"
                data-server-name={server.name}
              />
              <button className="delete-server-button" onClick={() => handleDeleteServer(server.serverId)}>Delete</button>
            </li>
          ))}
        </ul>
      </div>
      <hr className="roles-divider" />
      <div className="selected-server-name">
        {selectedServer && servers.find(server => server.serverId === selectedServer)?.name}
      </div>
      <div className="roles-content">
  {selectedServer ? (
    <>
      <button className="add-role-button" onClick={handleAddRole}>+</button>

      {/* Cabeçalho: Labels organizados */}
      <div className="role-row header-row">
        <div className="header-cell">Role</div>
        <div className="header-cell">NFT Collection</div>
        <div className="header-cell">Quantity</div>
        <div className="header-cell">Network</div>
        <div className="header-cell">Actions</div>
      </div>

      {/* Iteração sobre os roles existentes */}
      {rolesServer.map((roleServer, index) => (
        <div key={index} className="role-row">
          <div className="role-cell">
            <select
              value={roleServer.roleId}
              onChange={(e) => handleRoleChange(index, e.target.value)}
              className="select-field"
            >
              <option value="">Select Role</option>
              {roles.map((role) => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>

          <div className="role-cell">
            <input
              type="text"
              value={roleServer.nftCollection}
              onChange={(e) => handleNftCollectionChange(index, e.target.value)}
              placeholder="NFT Collection"
              className="input-field nft-collection-field"
            />
          </div>

          <div className="role-cell">
              <input
                type="number"
                value={roleServer.quantity || 1} // Define valor inicial como 1
                min="1" // Garante que o valor mínimo no input seja 1
                onChange={(e) => handleQuantityChange(index, e.target.value)}
                placeholder="Quantity"
                className="quantity-field"
              />
            </div>


          <div className="role-cell">
            <select
              value={roleServer.network}
              onChange={(e) => handleNetworkChange(index, e.target.value)}
              className="select-field-network"
            >
              <option value="Polygon">Polygon</option>
              <option value="Solana">Solana</option>
              <option value="Base">Base</option>
            </select>
          </div>

          <div className="actions role-cell">
            <button
              className="save-role-button"
              onClick={() => handleSaveRole(index)}
            >
              Save
            </button>
            <button
              className="remove-role-button"
              onClick={() => handleDeleteRole(index)}
            >
              X
            </button>
          </div>
        </div>
      ))}
    </>
  ) : (
    <div className="no-server-selected">Please select a server to view and add roles.</div>
  )}
</div>
    </div>
  );
};

export default DiscordRoles;
